import React, { ReactElement } from 'react'

import { ApiPost, ApiPostList, ApiPosts } from '@benkrejci/shared/dist/api/api'
import { usePosts } from './usePosts'
import { DataLoader } from '../DataLoader'
import { LoadingBox } from '../../utility/LoadingBox'
import { Box, css, Grid, Paper, Typography } from '@mui/material'
import { getResponsiveSpace } from '../../style/global'
import { RichText } from './RichText'
import { Link } from '../../utility/Link'
import { getPostUrl } from '@benkrejci/shared/src/api/route'
import { Media } from '../Media'
import { getDate } from '@benkrejci/shared/src/api/utility'

export const PostList = ({ widget }: { widget: ApiPostList }) => {
  const postsFetchState = usePosts(widget.allPosts)

  if (widget.allPosts) {
    return (
      <DataLoader fetchState={postsFetchState} showSpinner={false}>
        {(posts) => (
          <PostListInner
            isLoading={postsFetchState.isLoading}
            widget={widget}
            posts={posts}
          />
        )}
      </DataLoader>
    )
  }
  return <PostListInner isLoading={false} widget={widget} posts={widget.posts} />
}

export const PostListInner = ({
  isLoading,
  widget,
  posts,
}: {
  isLoading: boolean
  widget: ApiPostList | ApiPosts
  posts: ApiPostList['posts']
}): ReactElement => (
  <Grid container spacing={getResponsiveSpace()}>
    {isLoading
      ? new Array(2)
          .fill(0)
          .map((_zero, index) => (
            <PostListItem isLoading={true} post={undefined} key={index} />
          ))
      : posts?.map((post) => (
          <PostListItem isLoading={false} post={post} key={post.id} />
        ))}
  </Grid>
)

const PostListItem = ({
  isLoading,
  post,
}: {
  isLoading: boolean
  post: ApiPost | undefined
}) => {
  // Show cover if loading or there is a cover
  const showCover = post === undefined || post.cover !== undefined

  return (
    <Grid item container spacing={getResponsiveSpace()}>
      {showCover ? (
        <Grid item sm={12} md={3}>
          <LoadingBox isLoading={isLoading} minHeight="200px">
            {(content) =>
              post?.cover === undefined ? (
                content
              ) : (
                <Media
                  file={post.cover}
                  autoPlay={true}
                  controls={false}
                  muted={true}
                  height="100%"
                  width="100%"
                  maxHeight="100%"
                  imageFit="cover"
                />
              )
            }
          </LoadingBox>
        </Grid>
      ) : null}

      <Grid item sm={12} md={showCover ? 9 : 12}>
        <Paper
          css={css`
            min-height: 100%;
          `}
        >
          <Box p={4}>
            <div
              css={css`
                display: flex;
                align-items: start;
                justify-content: space-between;
              `}
            >
              <Typography variant="h2">
                <LoadingBox isLoading={isLoading}>
                  {(content) =>
                    post === undefined ? (
                      content
                    ) : (
                      <Link
                        href={getPostUrl(post)}
                        css={(theme) => css`
                          text-decoration: none;
                          color: ${theme.palette.text.primary};
                        `}
                      >
                        {post.title}
                      </Link>
                    )
                  }
                </LoadingBox>
              </Typography>
              <Typography variant="subtitle1">
                <LoadingBox isLoading={isLoading}>
                  {(content) =>
                    post === undefined
                      ? content
                      : getDate(post.date ?? post.createdAt!).toLocaleDateString()
                  }
                </LoadingBox>
              </Typography>
            </div>

            <LoadingBox
              isLoading={isLoading}
              fill="text"
              numTextParagraphs={1}
              numTextRows={4}
            >
              {(content) =>
                isLoading ? content : <RichText content={post?.description} />
              }
            </LoadingBox>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}
