import { Box, Grid, Paper, Typography } from '@mui/material'

import { WrapIf } from '../../utility/WrapIf'
import { Media } from '../Media'
import { RichText } from './RichText'
import { FragmentLink } from '../../utility/FragmentGenerator'
import { ApiPost, ApiPostPodcast, ApiSite } from '@benkrejci/shared/dist/api/api'
import { css } from '@emotion/react'
import { PostPodcast } from './PostPodcast'
import { LinkList } from './LinkList'
import { getPostUrl } from '@benkrejci/shared/dist/api/route'
import { Link } from '../../utility/Link'
import { getResponsiveSpace } from '../../style/global'
import { LoadingBox } from '../../utility/LoadingBox'
import { Widgets } from './Widgets'
import { getDate } from '@benkrejci/shared/src/api/utility'

export const Post = ({
  site,
  isLoading,
  post,
  isPage,
}: {
  site: ApiSite
  isLoading: boolean
  post: ApiPost | undefined
  isPage: boolean
}) => {
  if (post?.postType?.__component === 'post-type.podcast') {
    return <PostPodcast post={post as ApiPostPodcast} />
  }
  return (
    <Grid
      container
      spacing={getResponsiveSpace()}
      css={css`
        justify-content: space-between;
      `}
    >
      <Grid item>
        <LoadingBox isLoading={isLoading}>
          {(content) => (
            <>
              <Typography variant="h2">
                {isLoading ? (
                  content
                ) : post === undefined ? null : (
                  <WrapIf
                    if={isPage}
                    wrap={(children) => (
                      <Link
                        href={getPostUrl(post)}
                        css={(theme) => css`
                          text-decoration: none;
                          color: ${theme.palette.text.primary};
                        `}
                      >
                        {children}
                      </Link>
                    )}
                  >
                    {() => (
                      <>
                        {post.title}{' '}
                        {isPage ? null : <FragmentLink text={post.title} type="link" />}
                      </>
                    )}
                  </WrapIf>
                )}
              </Typography>

              {isLoading || post?.showDate ? (
                <Typography variant="subtitle1">
                  {isLoading
                    ? content
                    : post === undefined
                      ? null
                      : getDate(post.date ?? post.createdAt!).toLocaleDateString()}
                </Typography>
              ) : null}
            </>
          )}
        </LoadingBox>
      </Grid>

      {post?.links?.links?.length ? (
        <Grid item>
          <LinkList linkList={post.links.links} />
        </Grid>
      ) : null}

      {isLoading || (post?.cover && post?.showCover) ? (
        <Grid item xs={12}>
          {isLoading ? (
            <LoadingBox isLoading minHeight="25vh" />
          ) : post === undefined ? null : (
            <Paper
              elevation={1}
              css={css`
                overflow: hidden;
              `}
            >
              <Media
                file={post.cover!}
                maxHeight="50vh"
                width="100%"
                enableModal
                css={css`
                  background-color: transparent;
                `}
              />
            </Paper>
          )}
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <WrapIf
          if={post?.descriptionPaper}
          wrap={(children) => (
            <Paper elevation={1}>
              <Box p={4}>{children}</Box>
            </Paper>
          )}
        >
          {() => (
            <LoadingBox
              isLoading={isLoading}
              fill="text"
              numTextParagraphs={3}
              numTextRows={4}
            >
              {(content) =>
                isLoading ? (
                  content
                ) : post === undefined ? null : (
                  <RichText topHeadingLevel={3} useHeadingLinks>
                    {post.description}
                  </RichText>
                )
              }
            </LoadingBox>
          )}
        </WrapIf>
      </Grid>

      {!post?.widgetsMain?.length ? null : (
        <Grid item xs={12}>
          <Widgets site={site}>{post.widgetsMain}</Widgets>
        </Grid>
      )}
    </Grid>
  )
}
