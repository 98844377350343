"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDate = exports.fetchSingle = exports.fetchCollection = void 0;
const get = async (uri, options = {}) => fetch(uri, {
    // mode: process.env.NODE_ENV === 'development' ? 'no-cors' : 'cors',
    ...options,
});
const fetchCollection = async (uri) => {
    const response = await get(uri);
    if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
    return await response.json();
    // return flattenResponse(await response.json())
};
exports.fetchCollection = fetchCollection;
const fetchSingle = async (uri) => {
    const response = await get(uri);
    if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
    return await response.json();
};
exports.fetchSingle = fetchSingle;
const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;
const getDate = (date) => typeof date === 'string'
    ? // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date:
        // When the time zone offset is absent, date-only forms are interpreted as a
        // UTC time and date-time forms are interpreted as a local time.
        // Therefore, we add a time part to date-only forms to get them to be parsed
        // in local time.
        new Date(`${date}${dateOnlyRegex.exec(date) ? 'T00:00:00' : ''}`)
    : date;
exports.getDate = getDate;
