import type { Attribute, Common } from '@strapi/strapi'
import { APIResponse, APIResponseCollection } from './types'

const get = async (uri: string, options: RequestInit = {}) => fetch(uri, {
    // mode: process.env.NODE_ENV === 'development' ? 'no-cors' : 'cors',
    ...options,
  })

export const fetchCollection = async <T extends Common.UID.ContentType>(uri: string) => {
  const response = await get(uri)

  if (!response.ok) {
    throw new Error(`Failed to fetch data: ${response.statusText}`)
  }

  return await response.json() as APIResponseCollection<T>
  // return flattenResponse(await response.json())
}

export const fetchSingle = async <T extends Common.UID.ContentType>(uri: string) => {
  const response = await get(uri)

  if (!response.ok) {
    throw new Error(`Failed to fetch data: ${response.statusText}`)
  }

  return await response.json() as APIResponse<T>
}

const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/

export const getDate = (date: Attribute.DateTimeValue | Attribute.DateValue) =>
  typeof date === 'string'
    ? // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date:
      // When the time zone offset is absent, date-only forms are interpreted as a
      // UTC time and date-time forms are interpreted as a local time.
      // Therefore, we add a time part to date-only forms to get them to be parsed
      // in local time.
      new Date(`${date}${dateOnlyRegex.exec(date) ? 'T00:00:00' : ''}`)
    : date
